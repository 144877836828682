<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Статистика</h4>

      <!-- statistics -->
      <div v-if="Object.keys(semesterDisciplines).length" class="my-4">
        <template v-for="(semester, semesterKey) in semesterDisciplines" :key="semesterKey">

          <div v-if="Object.keys(semester).length" class="mb-4">

            <div class="row fw-bold mb-1">
              <div class="col-md-12 text-center">{{semesterKey}} семестр</div>
            </div>

            <!-- disciplines header -->
            <div class="row fw-bold">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-12 col-md-8">Название дисциплины</div>
                  <div class="col-12 col-md-4">Количество выбравших(%)</div>
                </div>
              </div>
              <div class="col-md-4">Диаграмма</div>
            </div>
            <!-- end disciplines header -->


            <!-- NOT KV disciplines -->
            <div v-for="(discipline, disciplineIndex) in semester.without_kv" :key="disciplineIndex"
                 class="border border-2 rounded-3 mt-2 mb-3 row py-2 border-light-blue">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <h5>{{discipline?.discipline_name}}</h5>
                  </div>
                  <div class="col-12 col-md-4">
                    <h5>{{discipline?.chosen_count}}
                      ({{basicDisciplinesModel[discipline.formation_education_program_id]?.percent}}%)
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <h5 class="text-center">
                  Общее количество студентов: {{discipline?.should_choose_count}}
                </h5>

                <div>
                  <Chart type="pie" :data="basicDisciplinesModel[discipline.formation_education_program_id]?.chartData"/>
                </div>
              </div>
            </div>
            <!-- end NOT KV disciplines -->


            <!-- KV disciplines -->
            <div v-for="(fGroup, fGroupIndex) in semester.kv" :key="fGroupIndex"
                 class="border border-2 rounded-3 mt-2 mb-3 row py-2 border-light-blue"
                 :style="{backgroundColor: colorForFGroup[fGroup[0].f_group_id]||'#fff'}">

              <div class="row">
                <div class="col-md-8">
                  <div v-for="(discipline, disciplineIndex) in fGroup" :key="disciplineIndex" class="my-3 row">
                    <div class="col-md-8">
                      <h5>
                        {{discipline?.discipline_name}}
                      </h5>
                    </div>
                    <div class="col-md-4">
                      <h5>{{discipline?.chosen_count}}
                        ({{+fGroup[0]?.should_choose_count?Math.round((discipline.chosen_count*100)/fGroup[0].should_choose_count):0}}%)
                      </h5>
                    </div>

                  </div>
                </div>

                <div class="col-md-4">
                  <h5 class="text-center">
                    Общее количество студентов: {{fGroup[0]?.should_choose_count}}
                  </h5>

                  <div>
                    <Chart type="pie" :data="electiveDisciplinesModel[fGroup[0].f_group_id]?.chartData"/>
                  </div>
                </div>
              </div>


            </div>
            <!-- end KV disciplines -->

          </div>

        </template>
      </div>
      <!-- end statistics -->


    </div>
  </div>
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"

  export default {
    name: "DisciplineSelectionStatistics",
    data() {
      return {
        loading: true,
        group_id: +this.$route.query.group_id || 0,
        colorForFGroup: {},
        colors: ['#E3F2FD', '#E8F5E9', '#FFF3E0', '#E0F2F1', '#FFEBEE'],
        basicDisciplinesModel: {},
        electiveDisciplinesModel: {},
      }
    },
    computed: {
      ...mapState('adviser', ['semesterDisciplines']),
    },

    methods: {
      ...mapActions('adviser', ['GET_DISCIPLINE_SELECTION_STATISTICS']),
      formChart(labels, data) {
        return {
          labels,
          datasets: [
            {
              data,
              backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
              hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"]
            }
          ]
        }
      },
      calculatePercent(part, all) {
        return all ? Math.round((part * 100) / +all) : 0
      },
      async setData() {
        if (!this.group_id) {
          return
        }

        this.basicDisciplinesModel = {}
        this.electiveDisciplinesModel = {}
        this.colorForFGroup = {}

        await this.GET_DISCIPLINE_SELECTION_STATISTICS(this.group_id)

        const basicDisciplinesModel = {}
        const electiveDisciplinesModel = {}

        const colorsLength = this.colors.length

        let fIndex = 0;

        for (let semester in this.semesterDisciplines) {

          this.semesterDisciplines[semester]?.without_kv.forEach(d => {
            let numberOfNonChosen = d?.should_choose_count - d?.chosen_count

            let disciplinePercent = this.calculatePercent(d?.chosen_count, d?.should_choose_count)
            let notChosenPercent = this.calculatePercent(numberOfNonChosen, d?.should_choose_count)

            let chartLabels = [`${d?.discipline_name} (${disciplinePercent}%)`, `Не выбрали (${notChosenPercent}%)`]

            basicDisciplinesModel[d.formation_education_program_id] = {
              percent: disciplinePercent,
              chartData: this.formChart(chartLabels, [d?.chosen_count, numberOfNonChosen])
            }
          })


          for (let fGroup in this.semesterDisciplines[semester].kv) {

            this.colorForFGroup[fGroup] = this.colors[fIndex % colorsLength]

            let chartLabels = [...this.semesterDisciplines[semester].kv[fGroup].map(i => i.discipline_name), 'Не выбрали']
            let chosenCounts = this.semesterDisciplines[semester].kv[fGroup].map(i => i.chosen_count)
            let numberOfNonChosen = this.semesterDisciplines[semester].kv[fGroup][0]?.should_choose_count - chosenCounts.reduce((sum, i) => sum + (+i), 0)


            let chartData = [...chosenCounts, numberOfNonChosen]
            let shouldChooseCount = +this.semesterDisciplines[semester].kv[fGroup][0]?.should_choose_count


            chartLabels = chartLabels.map((i, idx) => `${i} (${shouldChooseCount ? Math.round((chartData[idx] * 100) / +shouldChooseCount) : 0}%)`)

            electiveDisciplinesModel[fGroup] = {
              chartData: this.formChart(chartLabels, [...chosenCounts, numberOfNonChosen])
            }

            fIndex++
          }
        }

        this.basicDisciplinesModel = basicDisciplinesModel
        this.electiveDisciplinesModel = electiveDisciplinesModel
      },

    },
    async mounted() {
      await this.setData()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>